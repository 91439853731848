import { useLocation } from "react-router-dom";
import IconSVG from "../../../Utils/svg";
import { useAuthUser } from "react-auth-kit";
import { useState } from "react";
import { Button, ButtonGroup } from "@mui/material";
import { Edit, Expand } from "@mui/icons-material";

const Media = ({ project, hostUrl }) => {
	const location = useLocation();
	const userData = useAuthUser()();
	const [openUpdateMilestoneModal, setOpenMilestoneModal] = useState(false);
	const showUpdateMilestoneBtn = ((userData?.role?.toLowerCase() === "admin") && (location.pathname.search("/spp") === 0));
	// project.images.push(project.images[0]);
	return (
		<>
			<div className="flex-grow flex flex-col h-full" data-testid="project-tab_media_content">
				<div className="projectPage_media-navbar__gDfss">
					<div className="projectPage_media-nav__Ij9ni projectPage_media-nav-active__7QEl_">
						<p>All Pictures</p>
						<div className="projectPage_active-icon__x8GtR" />
					</div>
				</div>

				<div className=" projectPage_gallery___fDWv">
					{
						(project.images === undefined) ?
							(
								<div className='flex h-full flex-col items-center justify-center'>
									<div className=' mx-auto'>
										<img src={IconSVG.no_photo} alt='window wipe' className='w-96' />
									</div>
									<p className="mt-5 medium text-center text-2xl">No image yet</p>
									<p className="text-sm text-center text-input-border mt-3 w-10/12 lg:w-7/12 mx-auto">It seems that no one has updated or reviewed this project with pictures. Why not upload some with your review.</p>
									<div className="bg-white cursor-pointer text-primary hover:bg-primary hover:text-white transition ease-in-out duration-300 rounded-md px-4 py-1 mt-6">Back To Overview</div>
								</div>

							) :
							(project.images).map((image) => {
								return (
									<div className="w-full md:w-[48%] lg:w-[32.333333%] m-1">
										<div key={image.path} className="projectPage_gallery__item__xPnf7 overflow-visible w-full">
											<div className="projectPage_image__nKqqh">
												<span className="text-white" style={{ boxSizing: 'border-box', display: 'block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'absolute', inset: 0 }}>
													<img alt={project.name} src={`${hostUrl}${image.path}`} style={{ position: 'absolute', inset: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%', objectFit: 'cover' }} sizes="100vw" />
													{/* <ButtonGroup variant="outlined" aria-label="Action buttons">
														<Button><Expand /></Button>
														<Button><Edit /></Button>
													</ButtonGroup> */}
													<noscript />
												</span>
											</div>
										</div>
										<div class="projectPage_project-location-card__f7FjG">
											<div class="flex items-center text-xs space-x-1">
												<p class="text-dark-grey medium">{image.caption}</p>
											</div>
											<div class="flex items-center justify-between mt-4 text-2-xs">
												<p class="uppercase medium text-input-border">2024-09-23</p>
											</div>
										</div>
									</div>
								)
							})
					}
				</div>

			</div>
		</>
	)
}

export default Media
