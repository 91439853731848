import logo from "../assets/images/SPPA LOGO.png";
import developer from "../assets/images/developer.png";
import mda from "../assets/images/mda.png";
import landingPageImg from "../assets/images/review.jpeg";
import federal from "../assets/images/partner1_r23puw.png";

const SiteImages = {
	logo,
	mda,
	landingPageImg,
	developer,
	federal
}

export default SiteImages;