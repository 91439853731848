
const Icon = (props) => {
	return (
		<>
			{props.around_you && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#a)" stroke="inherit" strokeWidth="1.333">
					<path d="M8 1.334v5.667" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M7.999 1.334a6.667 6.667 0 1 0 5.264 2.576" strokeLinecap="round" />
					<path d="M7.999 4.334a3.667 3.667 0 1 0 2.895 1.417" strokeLinecap="round" />
					<path d="M8 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
				</g>
				<defs>
					<clipPath id="a">
						<path fill="inherent" d="M0 0h16v16H0z" />
					</clipPath>
				</defs>
			</svg>
			}
			{props.special_projects && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9 13h4v1H9v-1Zm0-2h6v1H9v-1Zm0-2h6v1H9V9Z" fill="inherent" />
				<path d="M10.275 5.609 8.001 1 5.726 5.609l-5.085.739 3.68 3.587L3.45 15l3.55-1.866v-1.13l-2.22 1.168.525-3.068.09-.518-.377-.367-2.23-2.173 3.081-.448.52-.075.233-.472 1.378-2.792 1.377 2.792.233.471.52.076 3.727.543.143-.991-3.726-.542Z" fill="inherent" />
			</svg>
			}
			{props.major_roads && <svg className="fill-inherit" width={18} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#a)">
					<path d="m17.912 12.583-4.368-10A.951.951 0 0 0 12.678 2h-3.05l.077.724a.25.25 0 0 1-.25.276h-.91a.25.25 0 0 1-.25-.276L8.373 2h-3.05a.951.951 0 0 0-.866.583l-4.368 10C-.202 13.246.26 14 .955 14h6.15l.323-3.053a.5.5 0 0 1 .497-.447h2.15a.5.5 0 0 1 .497.447L10.895 14h6.15c.695 0 1.157-.754.867-1.417Zm-9.774-8.36A.25.25 0 0 1 8.386 4h1.228a.25.25 0 0 1 .249.224l.144 1.362A.375.375 0 0 1 9.634 6H8.367a.375.375 0 0 1-.373-.414l.144-1.362ZM9.864 9.5H8.136a.5.5 0 0 1-.497-.553l.158-1.5A.5.5 0 0 1 8.295 7h1.41a.5.5 0 0 1 .498.447l.158 1.5a.5.5 0 0 1-.497.553Z" fill="inherent" />
				</g>
				<defs>
					<clipPath id="a">
						<path fill="inherent" d="M0 0h18v16H0z" />
					</clipPath>
				</defs>
			</svg>


			}
			{props.railways && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.001 1.334c-2.666 0-5.333.333-5.333 2.667v6.333a2.336 2.336 0 0 0 2.333 2.333l-1 1v.334h1.334l1.333-1.334h2.667l1.333 1.334h1.333v-.334l-1-1a2.336 2.336 0 0 0 2.334-2.333V4.001c0-2.334-2.667-2.667-5.334-2.667Zm-3 10c-.553 0-1-.447-1-1 0-.553.447-1 1-1 .554 0 1 .447 1 1 0 .553-.446 1-1 1Zm2.334-4.667H4V4.001h3.334v2.666ZM11 11.334c-.553 0-1-.447-1-1 0-.553.447-1 1-1 .554 0 1 .447 1 1 0 .553-.446 1-1 1Zm1-4.667H8.668V4.001h3.333v2.666Z" fill="inherent" />
			</svg>


			}
			{props.most_discussed && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.167 8.667a1.333 1.333 0 1 1-2.666.001 1.333 1.333 0 0 1 2.666 0Z" fill="inherent" />
				<path fillRule="evenodd" clipRule="evenodd" d="M4.833 9.334a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm0 .667a1.333 1.333 0 1 0 .001-2.666 1.333 1.333 0 0 0 0 2.666Z" fill="inherent" />
				<path d="M2 12.181c0-1.007 1.888-1.515 2.833-1.515.946 0 2.834.508 2.834 1.515v1.818H2v-1.818Z" fill="inherent" />
				<path fillRule="evenodd" clipRule="evenodd" d="M2.778 11.994c-.105.104-.111.163-.111.187v1.152H7V12.18c0-.023-.006-.083-.111-.187a1.804 1.804 0 0 0-.547-.335 4.276 4.276 0 0 0-1.509-.326c-.383 0-1.002.109-1.508.326a1.804 1.804 0 0 0-.547.335Zm2.055-1.328c-.945 0-2.833.508-2.833 1.515v1.818h5.667v-1.818c0-1.007-1.888-1.515-2.834-1.515Z" fill="inherent" />
				<path d="M12.499 8.667a1.333 1.333 0 1 1-2.666.001 1.333 1.333 0 0 1 2.666 0Z" fill="inherent" />
				<path fillRule="evenodd" clipRule="evenodd" d="M11.165 9.334a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334Zm0 .667a1.333 1.333 0 1 0 .001-2.666 1.333 1.333 0 0 0 0 2.666Z" fill="inherent" />
				<path d="M9.335 8a1.333 1.333 0 1 1-2.666 0 1.333 1.333 0 0 1 2.666 0Z" fill="inherent" />
				<path fillRule="evenodd" clipRule="evenodd" d="M8.001 8.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm0 .667a1.333 1.333 0 1 0 .001-2.666 1.333 1.333 0 0 0 0 2.666Z" fill="inherent" />
				<path d="M7.668 4.278a1.611 1.611 0 0 0-1.61-1.611H5a1.666 1.666 0 0 0-.06 3.33L5 6v.667s2.667-.39 2.667-2.39Zm1-.533A1.745 1.745 0 0 1 10.413 2h1.922a1.667 1.667 0 1 1 0 3.333h-.667v1s-3-.421-3-2.588Zm-.333 8.437c0-1.008 1.887-1.515 2.833-1.515.946 0 2.833.507 2.833 1.515V14H8.335v-1.818Z" fill="inherent" />
				<path fillRule="evenodd" clipRule="evenodd" d="M9.11 11.994c-.105.104-.11.164-.11.188v1.151h4.332v-1.151c0-.024-.006-.084-.11-.188a1.808 1.808 0 0 0-.548-.335 4.276 4.276 0 0 0-1.508-.326c-.384 0-1.003.11-1.509.326a1.808 1.808 0 0 0-.547.335Zm2.056-1.327c-.946 0-2.834.507-2.834 1.515V14H14v-1.818c0-1.008-1.888-1.515-2.833-1.515Zm-3.167 1v-.152c0-.559-.616-.964-1.335-1.214.418-.198.873-.3 1.335-.301.462 0 .918.103 1.335.3-.719.25-1.335.656-1.335 1.215v.152Z" fill="inherent" />
				<path fillRule="evenodd" clipRule="evenodd" d="M8 11.667v-.152c0-.298.174-.552.446-.762l.047-.035c.232-.169.527-.308.841-.417a3.101 3.101 0 0 0-2.67 0 3.304 3.304 0 0 1 .888.452c.272.21.447.464.447.762v.152Z" fill="inherent" />
			</svg>
			}
			{props.bridges && <svg className="fill-inherit" width={18} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 3c0-.553.447-1 1-1h16c.553 0 1 .447 1 1v1.112c0 .491-.397.888-.887.888A2.112 2.112 0 0 0 15 7.112v3.904c-.403 0-.806.121-1.15.365-.563.388-1.253.635-1.85.635V8a3 3 0 0 0-6 0v4.016c-.594 0-1.287-.247-1.847-.635A2.002 2.002 0 0 0 3 11.016V7.112A2.11 2.11 0 0 0 .888 5 .887.887 0 0 1 0 4.112V3Zm9.578 9.184c.703.485 1.563.816 2.422.816.84 0 1.731-.338 2.419-.816a.996.996 0 0 1 1.225.054c.45.371 1.015.656 1.581.787a1 1 0 0 1-.453 1.947 5.948 5.948 0 0 1-1.819-.781c-.906.487-1.922.809-2.953.809-.997 0-1.894-.31-2.512-.59A10.277 10.277 0 0 1 9 14.168a7.16 7.16 0 0 1-.488.24C7.894 14.691 6.997 15 6 15c-1.031 0-2.047-.322-2.953-.806-.419.262-1.053.603-1.819.781a1 1 0 0 1-.453-1.947 3.946 3.946 0 0 0 1.581-.787 1.005 1.005 0 0 1 1.225-.053C4.271 12.662 5.16 13 6 13c.86 0 1.719-.331 2.422-.816a.995.995 0 0 1 1.156 0Z" fill="inherent" />
			</svg>
			}
			{props.power && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="m14 5.83-1.227-1.227-2.424 2.424-1.376-1.376 2.424-2.424L10.17 2 7.746 4.424 6.19 2.868 5.015 4.042l6.942 6.942 1.175-1.174-1.556-1.556L14 5.83Zm-3.118 5.306L4.864 5.118c-1.3 1.558-2.774 3.966-1.755 6.037l-1.793 1.793a1.088 1.088 0 0 0 0 1.534l.202.202a1.088 1.088 0 0 0 1.534 0l1.793-1.793c2.071 1.018 4.478-.455 6.037-1.755Z" fill="inherent" />
			</svg>
			}
			{props.renewable_energy && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.999 2H3.332c-.367 0-.667.3-.667.667 0 .367.3.667.667.667h2.667c.366 0 .666-.3.666-.667C6.665 2.3 6.365 2 6 2ZM3.332 4.667h-2c-.367 0-.667.3-.667.667 0 .366.3.666.667.666h2c.367 0 .667-.3.667-.666 0-.367-.3-.667-.667-.667ZM2.665 14h2c.367 0 .667-.3.667-.666 0-.367-.3-.667-.667-.667h-2c-.366 0-.666.3-.666.667 0 .366.3.666.666.666Zm6.487-6.926c.5.153.867.52 1.047.973l2.846-4.74a1.52 1.52 0 0 0-2.34-1.893L8.42 3.554c-.267.246-.42.6-.42.966v2.62c.24-.1.653-.22 1.153-.066ZM7.072 8.18c.107-.346.32-.64.593-.846h-5.48a1.52 1.52 0 0 0-.42 2.98l3.007.86c.353.1.733.053 1.053-.14L7.62 9.96a1.658 1.658 0 0 1-.547-1.78Zm7.733 4.227-1.52-2.733a1.366 1.366 0 0 0-.84-.647l-2.12-.533c.02.213 0 .44-.066.66a1.652 1.652 0 0 1-1.594 1.18c-.406 0-.66-.147-.666-.147V14c-.734 0-1.334.6-1.334 1.334h4c0-.734-.6-1.334-1.333-1.334v-2.853l3.073 3.073a1.517 1.517 0 0 0 2.147 0c.48-.48.587-1.22.253-1.813Z" fill="inherent" />
				<path d="M8.374 9.62a.996.996 0 0 0 1.246-.668.996.996 0 0 0-.666-1.246.996.996 0 0 0-1.247.666c-.16.527.14 1.087.667 1.247Z" fill="inherent" />
			</svg>
			}
			{props.health && <svg className="fill-inherit" width={14} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#a)">
					<path d="M7 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM4 9.725a5.573 5.573 0 0 0-4 5.347c0 .512.416.928.928.928h12.144a.928.928 0 0 0 .928-.928 5.573 5.573 0 0 0-4-5.347v1.588a2.002 2.002 0 0 1 1.5 1.937v1.25c0 .275-.225.5-.5.5h-.5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5v-.75a.999.999 0 1 0-2 0V14c.275 0 .5.225.5.5s-.225.5-.5.5H8a.501.501 0 0 1-.5-.5v-1.25c0-.931.637-1.716 1.5-1.938V9.528a5.744 5.744 0 0 0-.572-.028H5.572c-.194 0-.385.01-.572.028v2.044a1.751 1.751 0 1 1-2.25 1.678c0-.794.528-1.463 1.25-1.678V9.725ZM4.5 14c.416 0 .75-.334.75-.75a.748.748 0 0 0-.75-.75.748.748 0 0 0-.75.75c0 .416.334.75.75.75Z" fill="inherent" /></g>
				<defs><clipPath id="a">
					<path fill="inherent" d="M0 0h14v16H0z" /></clipPath>
				</defs>
			</svg>
			}
			{props.schools && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M3.333 8.787v1.874c0 .487.267.94.693 1.173l3.334 1.82c.4.22.88.22 1.28 0l3.333-1.82c.426-.233.693-.686.693-1.173V8.788l-4.026 2.2c-.4.22-.88.22-1.28 0l-4.027-2.2Zm4.027-6.44L1.74 5.415a.671.671 0 0 0 0 1.174l5.62 3.066c.4.22.88.22 1.28 0L14 6.728v3.94c0 .366.3.666.666.666.367 0 .667-.3.667-.666V6.394a.67.67 0 0 0-.347-.587L8.64 2.348a1.36 1.36 0 0 0-1.28 0Z" fill="inherent" />
			</svg>
			}

			{props.housing && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12.662 1.334H6.008c-.74 0-1.34.6-1.34 1.34v3.758L1.534 9.527a.667.667 0 0 0 .468 1.14v3.334a.667.667 0 0 0 .666.666h10.667a.667.667 0 0 0 .667-.666V2.673c0-.74-.599-1.339-1.34-1.339Zm-5.379 8.74v3.26H3.335V9.622l1.991-1.966 1.957 2.002v.417Zm1.385-4.073H7.335V4.667h1.333v1.334Zm2.667 5.333h-1.333v-1.333h1.333v1.333Zm0-2.667h-1.333V7.334h1.333v1.333Zm0-2.666h-1.333V4.667h1.333v1.334Z" fill="inherent" />
				<path d="M4.668 10h1.333v1.333H4.668V10Z" fill="inherent" />
			</svg>
			}
			{props.oile_gas && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M2.667 14a.643.643 0 0 1-.475-.192.644.644 0 0 1-.192-.475c0-.189.064-.347.192-.474a.643.643 0 0 1 .475-.192h.666v-4h-.666a.643.643 0 0 1-.475-.192A.643.643 0 0 1 2 8c0-.189.064-.347.192-.475a.645.645 0 0 1 .475-.192h.666v-4h-.666a.643.643 0 0 1-.475-.192A.643.643 0 0 1 2 2.667c0-.19.064-.348.192-.476A.645.645 0 0 1 2.667 2h10.666c.19 0 .347.064.475.191a.646.646 0 0 1 .192.476.643.643 0 0 1-.192.474.644.644 0 0 1-.475.192h-.666v4h.666c.19 0 .347.064.475.192A.646.646 0 0 1 14 8a.643.643 0 0 1-.192.475.644.644 0 0 1-.475.192h-.666v4h.666c.19 0 .347.064.475.192a.643.643 0 0 1 .192.474.644.644 0 0 1-.192.475.644.644 0 0 1-.475.192H2.667ZM8 10.667c.556 0 1.028-.192 1.417-.576A1.88 1.88 0 0 0 10 8.7c0-.356-.075-.656-.225-.9-.15-.244-.458-.633-.925-1.167L8.5 6.25a.632.632 0 0 0-.5-.233c-.2 0-.367.077-.5.233l-.333.383c-.467.534-.778.923-.934 1.167-.155.244-.233.544-.233.9 0 .544.194 1.008.583 1.391.39.384.861.576 1.417.576Z" fill="inherent" />
			</svg>
			}
			{props.ports && <svg className="fill-inherit" width={16} height={14} fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)">
				<path d="M7.719-1.5v2.781H8c.466 0 .719.366.744.738a.646.646 0 0 1-.147.481c-.11.125-.284.219-.597.219-.156 0-.347-.088-.487-.232-.144-.14-.232-.33-.232-.487H6.72c0 .344.162.653.393.888.235.23.544.393.888.393.438 0 .794-.156 1.016-.406a1.21 1.21 0 0 0 .29-.894C9.27 1.434 8.888.881 8.281.75V-1.5H7.72ZM6.222 2.89 1.917 5.72h1.022l3.59-2.36-.307-.468Zm3.556 0-.306.47 3.59 2.359h1.02L9.777 2.89ZM1.281 6.282v6.938H14.72V6.28H1.28ZM2.47 7h.562v5.5H2.47V7Zm1.5 0h.562v5.5H3.97V7Zm1.5 0h.562v5.5H5.47V7Zm1.5 0h.562v5.5H6.97V7Zm1.5 0h.562v5.5H8.47V7Zm1.5 0h.562v5.5H9.97V7Zm1.5 0h.562v5.5h-.562V7Zm1.5 0h.562v5.5h-.562V7Z" fill="inherent" /></g><defs><clipPath id="a"><path fill="inherent" d="M0 0h16v14H0z" /></clipPath></defs>
			</svg>
			}
			{props.agriculture && <svg className="fill-inherit" width={20} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15.2 9.875c-1.215 0-2.2.923-2.2 2.063 0 1.139.985 2.062 2.2 2.062 1.215 0 2.2-.923 2.2-2.063 0-1.139-.985-2.062-2.2-2.062Zm0 2.625c-.33 0-.6-.252-.6-.563 0-.31.27-.562.6-.562.331 0 .6.252.6.563 0 .31-.269.562-.6.562Zm2-6.75h-1.6v-.942c0-.331.118-.65.329-.91.11-.136.089-.33-.033-.457l-.54-.567a.425.425 0 0 0-.618.018A2.892 2.892 0 0 0 14 4.809v.942h-2.558L10.04 2.683a1.143 1.143 0 0 0-.442-.497A1.256 1.256 0 0 0 8.936 2H5.6c-.662 0-1.2.505-1.2 1.125v3.434a.571.571 0 0 0-.722.035l-.778.729a.493.493 0 0 0 0 .729l.127.118a3.435 3.435 0 0 0-.298.674H2.55c-.304 0-.55.23-.55.515v1.031c0 .285.246.516.55.516h.179c.074.232.173.456.297.673l-.126.119a.493.493 0 0 0 0 .73l.778.728a.576.576 0 0 0 .777 0l.127-.118c.231.117.47.21.718.279v.167c0 .285.246.516.55.516h1.1c.304 0 .55-.23.55-.516v-.167c.247-.07.486-.162.718-.28l.126.12a.576.576 0 0 0 .778 0l.778-.73a.493.493 0 0 0 0-.729l-.127-.118c.125-.217.224-.441.298-.674h.18c.303 0 .55-.23.55-.515v-.14h2.013A3.054 3.054 0 0 1 15.2 9.124c.466 0 .902.108 1.295.286l1.27-1.19A.727.727 0 0 0 18 7.69V6.5c0-.414-.358-.75-.8-.75Zm-10.8 6c-1.104 0-2-.84-2-1.875C4.4 8.84 5.296 8 6.4 8c1.105 0 2 .84 2 1.875 0 1.036-.895 1.875-2 1.875Zm.55-6H6V3.5h2.672l1.03 2.25H6.95Z" fill="inherent" />
			</svg>
			}
			{props.transport && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)">
				<path d="M12.667 10.666c-.8 0-1.506-.25-2.117-.75A3.221 3.221 0 0 1 9.4 7.999H7.417l-.8-1.333H9.4c.056-.244.13-.478.225-.7.095-.222.22-.433.375-.633H5.8L5 3.999h5.7l-.733-2H7.333V.666h2.634c.289 0 .544.08.766.241.223.162.384.376.484.642l.9 2.45h.55c.922 0 1.708.325 2.358.975.65.65.975 1.436.975 2.359 0 .922-.325 1.708-.975 2.358-.65.65-1.436.975-2.358.975Zm0-1.333c.555 0 1.027-.195 1.416-.584.39-.389.584-.86.584-1.416 0-.556-.195-1.028-.584-1.417a1.929 1.929 0 0 0-1.416-.583h-.05l.65 1.783-1.267.45-.633-1.75a1.954 1.954 0 0 0-.517.683 1.978 1.978 0 0 0-.183.834c0 .555.194 1.027.583 1.416.389.39.861.584 1.417.584Zm-8 6a1.929 1.929 0 0 1-1.417-.584 1.929 1.929 0 0 1-.583-1.416H0v-4h1.333v-2H0V5.999h4.667l2 3.334H8c.367 0 .68.13.942.391.26.261.391.575.391.942v1.333c0 .367-.13.681-.391.942-.261.261-.575.392-.942.392H6.667c0 .555-.195 1.027-.584 1.416a1.929 1.929 0 0 1-1.416.584Zm-2-6h2.45l-1.2-2h-1.25v2Zm2 4.666a.646.646 0 0 0 .475-.192.644.644 0 0 0 .191-.474.645.645 0 0 0-.191-.475.646.646 0 0 0-.475-.192.646.646 0 0 0-.476.192.645.645 0 0 0-.191.475c0 .189.064.347.191.474a.646.646 0 0 0 .476.192Z" fill="inherent" /></g>
				<defs><clipPath id="a"><path fill="inherent" d="M0 0h16v16H0z" /></clipPath></defs>
			</svg>
			}
			{props.newly_added && <svg className="fill-inherit" width={12} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.991 1.177v-.106c0-.107 0-.32-.104-.427 0-.106-.104-.213-.209-.32-.313-.32-.73-.426-1.148-.213-.104.107-.208.107-.313.213-.104.107-.208.214-.208.32 0 .107-.105.213-.105.427v.106C2.087 1.711 0 4.163 0 7.15v4.586c0 .426.313.853.835.853h10.33c.418 0 .835-.32.835-.853V7.149c0-2.986-2.191-5.438-5.009-5.972Zm3.444 8.745v1.173H1.67V7.149c0-2.453 1.982-4.479 4.382-4.479 2.4 0 4.383 2.026 4.383 4.48v2.772ZM3.86 13.867C3.86 15.04 4.798 16 5.945 16c1.148 0 2.087-.96 2.087-2.133H3.86Z" fill="inherent" />
			</svg>
			}
			{props.nearing_completed && <svg className="fill-inherit" width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M13.076 2.14c.234 0 .424-.205.424-.458V.958c0-.253-.19-.458-.424-.458H2.924C2.69.5 2.5.705 2.5.958v.724c0 .253.19.458.424.458h.264v11.72h-.264c-.234 0-.424.204-.424.458v.723c0 .253.19.459.424.459h10.152c.234 0 .424-.206.424-.459v-.723c0-.254-.19-.459-.424-.459h-.264V2.141h.264Zm-9.43 11.72V2.14h.43c-.11 0-.201.084-.201.188v.563c0 .103.09.186.2.187 0 2.072.332 4.086 2.734 4.78l.118.038c.104.033.228.072.292.104-.064.032-.187.07-.29.103l-.113.037c-2.408.696-2.74 2.71-2.74 4.782-.111 0-.2.084-.2.187v.563c0 .103.09.188.201.188h-.431Zm5.29-5.309.124.04c1.701.491 2.366 1.61 2.404 4.097h-3.26C8.171 11.53 8.089 8.579 8.089 8c0-.196.363-.471.53-.57.705-.416 1.611-1.24 1.611-2.096H5.77c0 .857.907 1.68 1.611 2.096.167.099.53.374.53.57 0 .595-.082 3.534-.114 4.688h-3.26c.038-2.486.703-3.606 2.411-4.1l.117-.036c.329-.104.638-.202.638-.552 0-.35-.31-.448-.64-.552L6.94 7.41c-1.702-.492-2.366-1.612-2.404-4.097h6.929c-.039 2.485-.704 3.605-2.411 4.099l-.114.036c-.33.103-.642.202-.642.552 0 .35.31.448.639.551Zm3.418 5.309h-.43c.11 0 .201-.085.201-.188v-.563c0-.103-.09-.186-.2-.187 0-2.072-.333-4.087-2.734-4.78l-.12-.038A2.494 2.494 0 0 1 8.781 8c.064-.032.189-.071.293-.104l.11-.035c2.408-.696 2.74-2.71 2.74-4.783.11 0 .201-.084.201-.188v-.562c0-.103-.09-.187-.201-.187h.43V13.86Z" fill="inherent" />
			</svg>
			}
			{props.list_menu && <svg className="fill-inherit" width={38} height={38} fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="set-view_map">
				<rect x="0.5" y="0.5" width={37} height={37} rx="6.5" fill="currentColor" stroke="#fff" />
				<path d="m15.368 11.79-2.736-.914A2 2 0 0 0 10 12.774v11.783a2 2 0 0 0 1.368 1.898l4 1.333a2 2 0 0 0 1.264 0l4.736-1.578a2 2 0 0 1 1.264 0l2.736.912A2.001 2.001 0 0 0 28 25.223V13.441a2 2 0 0 0-1.367-1.898l-4-1.333a2 2 0 0 0-1.265 0l-4.737 1.578a2 2 0 0 1-1.264 0l.001.001Z" stroke="#DCDCDC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M16 12v16M22 10v16" stroke="#DCDCDC" strokeWidth="1.5" strokeLinejoin="round" />
			</svg>
			}
			{props.grid_menu && <svg className="fill-inherit" width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width={28} height={28} rx="8.167" fill="currentColor" />
				<path fill="inherit" stroke="inherit" strokeWidth="1.167" strokeLinecap="round" strokeLinejoin="round" d="M8.105 8.105h4.421v4.421H8.105zM15.477 8.105h4.421v4.421h-4.421zM15.477 15.475h4.421v4.421h-4.421zM8.105 15.475h4.421v4.421H8.105z" />
			</svg>
			}
			{props.drop_down && <svg className="fill-inherit" width={8} height={6} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M4 5.26a.52.52 0 0 1-.37-.153L.15 1.627A.522.522 0 0 1 .888.888L4 4 7.112.888a.522.522 0 0 1 .738.739l-3.48 3.48A.52.52 0 0 1 4 5.26Z" fill="#A0AFBF" />
			</svg>
			}
		</>
	);
}

export default Icon;

